import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';

import ActiveCreditCard from 'web/components/active_credit_card';
import CreditCardForm from 'web/components/stripe_credit_card_form';
import {actions as creditCardFormActions} from 'web/components/stripe_credit_card_form/duck';
import SubmitButton from 'web/components/submit_button';
import MinimalLayout from 'web/components/minimal_layout';
import {getText} from 'infra/shared_text';
import {formatPromoCode} from 'infra/formatters/money';
import {formatCents} from 'web/helpers/money';
import {AppDispatch, PageType} from 'web/helpers/redux_client';
import StripeContext from 'web/components/stripe_credit_card_form/stripe_context';

import StepsHeader from '../components/steps_header';
import PromoOrGiftCodeForm from './components/promo_or_gift_code_form';
import {actions, reducer} from './duck';
import {actions as navigationActions} from '../../helpers/navigation_duck';
import {StoreData} from './controller';

const PaymentDetailsPage: PageType<StoreData> = () => {
  const [setupIntentLoading, setSetupIntentLoading] = useState(true);

  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((s: StoreData) => ({
    user: s.user,
    hasActiveCard: s.hasActiveCard,
    stripeKey: s.stripeKey,
    promoCodes: s.promoCodes,
    creditCardForm: s.creditCardForm,
    isEditingCreditCard: s.isEditingCreditCard,
    isSaving: s.paymentDetailsPage?.isSaving,
    promoError: s.paymentDetailsPage?.promoError?.message,
    showCaptcha: s.showCaptcha,
    recaptchaSitekey: s.recaptchaSitekey,
  }));

  useEffect(() => {
    dispatch(
      creditCardFormActions.createSetupIntent(() => {
        setSetupIntentLoading(false);
      }),
    );
  }, [dispatch]);

  const onSave = (): void => {
    dispatch(actions.submitPromoOrGiftCode());
  };

  const onChangeCreditCard = (): void => {
    dispatch(actions.changeCreditCard());
  };

  const onCreditCardSave = (): void => {
    dispatch(navigationActions.navigate('/basket/review'));
  };

  const onCancel = (): void => {
    dispatch(navigationActions.navigate('/basket/review'));
  };

  const renderHeader = (): React.ReactElement => {
    const title = state.hasActiveCard
      ? state.isEditingCreditCard
        ? 'Edit your payment'
        : getText('enterCode')
      : 'Enter your payment';

    return (
      <h1 className="checkout__title">
        <span>{title}</span>
      </h1>
    );
  };

  const renderExistingDetails = (): React.ReactElement | null => {
    if (!state.hasActiveCard) return null;

    const showStoreCredit = (state.user?.availableBalance ?? 0) > 0;

    const showPromoCodes = state.promoCodes.length > 0;

    return (
      <div className="payment-details-page summary-details raised-tile">
        <div className="raised-tile__header">
          <span className="header">Your Payment</span>
          {!state.isEditingCreditCard && (
            <a className="change raised-tile__header-link" onClick={onChangeCreditCard}>
              change
            </a>
          )}
        </div>
        <div className="raised-tile__body summary-content">
          {state.user?.active_card != null && !isEmpty(state.user?.active_card) ? (
            <div>
              <label>Current Card</label>
              <ActiveCreditCard activeCard={state.user.active_card} mini />
            </div>
          ) : null}
          {showStoreCredit && (
            <div>
              <label>Available Gift Credit</label>
              <p className="gift-credit">{formatCents(state.user?.availableBalance ?? 0)}</p>
            </div>
          )}
          {showPromoCodes && (
            <div>
              <label>Promo Code</label>
              {state.promoCodes.map((promo) => (
                <p key={promo.code} className="promo">
                  {formatPromoCode(promo)}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  /* now only used for the promo code */
  const renderControls = (): React.ReactElement => {
    const buttonLabel = state.hasActiveCard ? 'Save' : 'Save & Continue';
    return (
      <div className="payment-details-page__controls checkout__button-row">
        <SubmitButton
          className="button button--checkout save"
          workingLabel="Saving..."
          onClick={onSave}
        >
          {buttonLabel}
        </SubmitButton>
        {state.hasActiveCard ? (
          <button
            className="button button--checkout button-is-secondary cancel"
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
        ) : null}
      </div>
    );
  };

  return (
    <MinimalLayout>
      <Helmet>
        <title>Checkout | Good Eggs</title>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        <html className="with-fixed-minimal-footer" />
        <body className="basket_payment_page" />
        <script src="https://js.stripe.com/v3/" />
        <script src="https://www.google.com/recaptcha/api.js" />
      </Helmet>

      <StepsHeader currentStep={2} />
      <div className="payment-details-page payment-details-view narrow-page gutter">
        {renderHeader()}
        <hr className="checkout__title-hr" />
        {renderExistingDetails()}
        {state.isEditingCreditCard ? (
          <div className="payment-details-page" data-testid="credit-card-form">
            <StripeContext>
              <CreditCardForm
                buttonText={state.hasActiveCard ? 'Save' : 'Save & Continue'}
                checkoutSeparator
                captchaToken={state.creditCardForm?.captchaToken}
                creditCardError={state.creditCardForm?.creditCardError}
                onCreditCardSave={onCreditCardSave}
                onCreditCardCancel={state.hasActiveCard ? onCancel : undefined}
                showCaptcha={state.showCaptcha}
                recaptchaSitekey={state.recaptchaSitekey}
                setupIntentId={state.creditCardForm?.setupIntentId}
                isSetupIntentLoading={setupIntentLoading}
              />
            </StripeContext>
          </div>
        ) : (
          <div className="promo-or-gift">
            <label>Promo/Gift/School Code</label>
            <PromoOrGiftCodeForm onSubmit={onSave} />
            {state.promoError ? (
              <span>
                <i className="icon icon-circle-exclamation" />
                <div className="error">{state.promoError}</div>
              </span>
            ) : null}
            <hr className="checkout__separator" />
            {renderControls()}
          </div>
        )}
        {!state.hasActiveCard ? (
          <div className="basket-page__back-link">
            <a className="back-to-basket checkout__link" href="/basket">
              <i className="icon icon-chevron left" />
              Return to basket
            </a>
          </div>
        ) : null}
      </div>
    </MinimalLayout>
  );
};

PaymentDetailsPage.pageName = 'Payment';

PaymentDetailsPage.reducer = reducer;

export default PaymentDetailsPage;
