import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

export const InputWithErrors = ({input, meta, className, placeholder}) => (
  <div className="promo-code-form__field">
    <input className={className} {...input} placeholder={placeholder} type="text" />
    {meta.touched && meta.error ? (
      <div className="promo-code-form__error error">{meta.error}</div>
    ) : null}
  </div>
);

InputWithErrors.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export class PromoCodeForm extends Component {
  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit();
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <Field
          name="promoCode"
          className="form-control promo-or-gift-code"
          placeholder="Enter your code"
          component={InputWithErrors}
        />
      </form>
    );
  }
}

function validatePromoCode({promoCode}) {
  if (!promoCode || promoCode === '') {
    return {promoCode: 'Promo or Gift Code must not be empty'};
  }
}

PromoCodeForm.propTypes = {
  onSubmit: PropTypes.func,
};

const PromoCodeReduxForm = reduxForm({
  form: 'promoOrGiftCode',
  validate: validatePromoCode,
  touchOnBlur: true,
})(PromoCodeForm);

export default connect(() => ({}))(PromoCodeReduxForm);
